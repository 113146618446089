import React from 'react';

function HeroSection() {
  return (
    <section className="bg-black text-white text-center py-20 px-10">
      <h1 className="text-5xl font-bold mb-10">Say Goodbye to</h1>
      <ul className="text-2xl space-y-4 font-semibold leading-relaxed">
        <li>Manual Formulary Checks</li>
        <li>Rx Coverage Confusions</li>
        <li>Prescription Delays</li>
        <li>Endless Phone Calls</li>
      </ul>
    </section>
  );
}

export default HeroSection;
