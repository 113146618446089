import React from 'react';

function AboutPage() {
  return (
    <section className="bg-white text-black text-center py-16 px-10">
      <h2 className="text-4xl font-bold mb-8">About MedHug</h2>
      <p className="text-xl mb-8 leading-relaxed">
        MedHug is a Public Benefit Corporation, started by a Stanford PhD graduate, with the mission to drive the transformation of medication accessibility, affordability, and quality for all.
      </p>
      <p className="text-xl mb-8 leading-relaxed">
        People with love are making the world a healthier and better place, and we change healthcare with love.
      </p>
      <div className="flex justify-center">
        <a
          href="/"
          className="bg-black text-white px-8 py-4 rounded-full hover:bg-gray-800 text-lg font-semibold transition duration-300"
        >
          Back to Home
        </a>
      </div>
    </section>
  );
}

export default AboutPage;
