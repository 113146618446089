import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/Logo_wordmark_blackbg.svg';

function Header() {
  return (
    <header className="p-8 bg-black text-white flex justify-center items-center relative">
      <div className="flex items-center">
        <img src={logo} alt="MedHug Logo" className="h-16" />
      </div>
      <nav className="absolute top-8 right-8">
        <ul className="flex space-x-6">
          <li><Link to="/about" className="hover:text-gray-300 text-xl">About</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;