import React from 'react';

function Footer() {
  return (
    <footer className="bg-black text-white text-center py-4">
      <div className="text-sm">
        <a href="https://www.linkedin.com/company/medhug/?viewAsMember=true" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">LinkedIn</a>
      </div>
    </footer>
  );
}

export default Footer;
