import React from 'react';

function AboutSection() {
  return (
    <section className="bg-white text-black text-center py-16 px-10">
      <h2 className="text-4xl font-bold mb-6">Hello MedHug!</h2>
      <p className="text-xl mb-8 leading-relaxed">
        MedHug provides <span className="font-semibold">instant insurance coverage information</span> and <span className="font-semibold">cost-effective prescribing options</span>, reducing delays and making medications more accessible.
      </p>
      <div className="flex justify-center space-x-6">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScF0XJEJzeP052lJgFAE-0GzgcwnZvimPHAps5yX71C3tI1cw/viewform?usp=pp_url"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-black text-white px-8 py-4 rounded-full hover:bg-gray-800 text-lg font-semibold transition duration-300"
        >
          Join Waitlist Now
        </a>
        <a href="https://calendly.com/yiwolf-medhug/30min" className="text-black underline hover:text-gray-600">Or Schedule a meeting with the team</a>
      </div>
    </section>
  );
}

export default AboutSection;
